var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "emergency-records-bar" },
    [
      _c("div", { staticClass: "detail-title", style: "left: -56px" }, [
        _c("img", {
          staticClass: "icon-back",
          attrs: { src: "/img/project/icon-back.png", alt: "" },
          on: { click: _vm.backToList },
        }),
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
      ]),
      _c("div", { staticClass: "emergency-records-title" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("emergency.records"))),
        ]),
        _c(
          "div",
          { staticClass: "clickable-txt", on: { click: _vm.exportRecords } },
          [_vm._v(_vm._s(_vm.$t("export")))]
        ),
      ]),
      _c(
        "div",
        { staticClass: "emergency-records-search" },
        [
          _c(
            "el-input",
            {
              staticClass: "uav-input",
              staticStyle: { width: "250px" },
              attrs: { size: "small", placeholder: _vm.$t("emergency.name") },
              on: { change: _vm.searchHandle },
              model: {
                value: _vm.form.name,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "name", $$v)
                },
                expression: "form.name",
              },
            },
            [
              _c("template", { slot: "suffix" }, [
                _c("div", { staticClass: "search-icon-container" }, [
                  _c("i", {
                    staticClass: "el-icon-search",
                    on: { click: _vm.searchHandle },
                  }),
                ]),
              ]),
            ],
            2
          ),
          _c("div", { staticClass: "margin-separate" }),
          _c("el-date-picker", {
            staticClass: "uav-date-range",
            staticStyle: { width: "250px" },
            attrs: {
              size: "small",
              type: "datetimerange",
              "range-separator": _vm.$t("to"),
              "start-placeholder": _vm.$t("startTime"),
              "end-placeholder": _vm.$t("endTime"),
              "default-time": ["00:00:00", "23:59:59"],
              "value-format": "yyyy-MM-dd HH:mm:ss",
            },
            on: { change: _vm.searchHandle },
            model: {
              value: _vm.form.time,
              callback: function ($$v) {
                _vm.$set(_vm.form, "time", $$v)
              },
              expression: "form.time",
            },
          }),
          _vm.detailInfo.status > 2
            ? _c("div", { staticClass: "margin-separate" })
            : _vm._e(),
          _vm.detailInfo.status > 2
            ? _c(
                "span",
                {
                  staticClass: "clickable-txt",
                  on: { click: _vm.openTaskSummary },
                },
                [_vm._v(_vm._s(_vm.$t("emergency.viewSummary")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "record-item-box",
          attrs: {
            "element-loading-text": _vm.$t("loading"),
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.8)",
          },
        },
        _vm._l(_vm.emergencyRecordsList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "record-item",
              class:
                _vm.selectEmergencyRecord.id === item.id
                  ? "record-item-active"
                  : "",
              on: {
                mouseleave: _vm.leaveRecord,
                click: function ($event) {
                  return _vm.chooseRecord(item)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "record-title" },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { content: item.name, placement: "top" } },
                    [
                      _c("div", { staticClass: "record-describe-mane" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  ),
                  _c("span", { staticClass: "control-icon" }, [
                    item.id === _vm.selectEmergencyRecord.id
                      ? _c("i", {
                          staticClass: "el-icon-aim",
                          staticStyle: { "margin-right": "4px" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.flyTo(item)
                            },
                          },
                        })
                      : _vm._e(),
                    item.id === _vm.selectEmergencyRecord.id
                      ? _c("i", {
                          staticClass: "el-icon-delete",
                          class: _vm.deleting ? "bgc-red" : "",
                          staticStyle: { "margin-right": "4px" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.deleteEmergencyRecord(item)
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "record-task" }, [
                _vm._v(_vm._s(item.eventTypeName ? item.eventTypeName : "--")),
              ]),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: item.emergencyTaskName + " -- " + item.flyTaskName,
                    placement: "right",
                  },
                },
                [
                  _c("div", { staticClass: "record-describe" }, [
                    _vm._v(
                      _vm._s(item.emergencyTaskName) +
                        " -- " +
                        _vm._s(item.flyTaskName)
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "record-time" }, [
                _vm._v(_vm._s(item.startTime) + " -- " + _vm._s(item.endTime)),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "left-table-pagination-line" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page,
              "page-size": _vm.size,
              "pager-count": 3,
              size: "small",
              background: "",
              layout: "total, prev, pager, next",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
              "update:currentPage": function ($event) {
                _vm.page = $event
              },
              "update:current-page": function ($event) {
                _vm.page = $event
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "common-map-dialog private-mark-markpage-dialog",
          attrs: {
            title: _vm.$t("emergency.endTaskSummary"),
            visible: _vm.taskSummaryVisible,
            width: "30%",
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.closeTaskSummary,
          },
          on: {
            "update:visible": function ($event) {
              _vm.taskSummaryVisible = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v("\n      " + _vm._s(_vm.detailInfo.summary) + "\n    "),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeTaskSummary } }, [
                _vm._v(_vm._s(_vm.$t("cancelText"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.closeTaskSummary },
                },
                [_vm._v(_vm._s(_vm.$t("submitText")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="emergency-records-bar">
    <div
      :style="`left: -56px`"
      class="detail-title">
      <img
        class="icon-back"
        @click="backToList"
        src="/img/project/icon-back.png"
        alt=""
      />
      {{ title }}
    </div>
    <div class="emergency-records-title">
      <div class="title">{{$t('emergency.records')}}</div>
      <div class="clickable-txt" @click="exportRecords">{{$t('export')}}</div>
    </div>
    <div class="emergency-records-search">
      <el-input
        class="uav-input"
        size="small"
        @change="searchHandle"
        style="width: 250px;"
        v-model="form.name"
        :placeholder="$t('emergency.name')"
      >
        <template slot="suffix">
          <div class="search-icon-container">
            <i class="el-icon-search" @click="searchHandle"></i>
          </div>
        </template>
      </el-input>
      <div class="margin-separate"></div>
      <el-date-picker
        size="small"
        v-model="form.time"
        style="width: 250px"
        class="uav-date-range"
        type="datetimerange"
        :range-separator="$t('to')"
        :start-placeholder="$t('startTime')"
        :end-placeholder="$t('endTime')"
        :default-time="['00:00:00','23:59:59']"
        @change="searchHandle"
        value-format="yyyy-MM-dd HH:mm:ss"
        >
      </el-date-picker>
      <div v-if="detailInfo.status > 2" class="margin-separate"></div>
      <span v-if="detailInfo.status > 2" @click="openTaskSummary" class="clickable-txt">{{$t('emergency.viewSummary')}}</span>
    </div>
    <div class="record-item-box"
      v-loading="loading"
      :element-loading-text="$t('loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      >
      <div
        class="record-item"
        v-for="item in emergencyRecordsList"
        :key="item.id"
        :class="selectEmergencyRecord.id === item.id? 'record-item-active': ''"
        @mouseleave="leaveRecord"
        @click="chooseRecord(item)">
        <div class="record-title">
          <el-tooltip :content="item.name" placement="top">
              <div class="record-describe-mane">{{item.name}}</div>
          </el-tooltip>
          <span class="control-icon">
            <i
                @click.stop="flyTo(item)"
                v-if="item.id===selectEmergencyRecord.id"
                style="margin-right: 4px"
                class="el-icon-aim">
            </i>
            <i
                @click.stop="deleteEmergencyRecord(item)"
                v-if="item.id===selectEmergencyRecord.id"
                :class="deleting? 'bgc-red': ''"
                style="margin-right: 4px"
                class="el-icon-delete">
            </i>
          </span>
        </div>
        <div class="record-task">{{ item.eventTypeName? item.eventTypeName:'--' }}</div>
        <el-tooltip :content="`${item.emergencyTaskName} -- ${item.flyTaskName}`" placement="right">
            <div class="record-describe">{{ item.emergencyTaskName }} -- {{item.flyTaskName}}</div>
        </el-tooltip>
        <div class="record-time">{{ item.startTime }} -- {{ item.endTime }}</div>
      </div>
    </div>
    <div class="left-table-pagination-line">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="size"
            :pager-count="3"
            size="small"
            background
            layout="total, prev, pager, next"
            :total="total"/>
    </div>
    <!-- 查看任务总结弹窗 -->
    <el-dialog
      :title="$t('emergency.endTaskSummary')"
      :visible.sync="taskSummaryVisible"
      width="30%"
      append-to-body
      class="common-map-dialog private-mark-markpage-dialog"
      :close-on-click-modal="false"
      :before-close="closeTaskSummary">
      <p>
        {{detailInfo.summary}}
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeTaskSummary">{{ $t('cancelText') }}</el-button>
        <el-button type="primary" @click="closeTaskSummary">{{ $t('submitText') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { mapGetters, mapMutations } from "vuex";
  import { getEmergencyRecordPage, deleteEmergencyRecord } from "@/api/emergency/index";
  import {
    getEmergencytaskDetail
  } from "@/api/emergency/index";

  export default {
    name: "operationBar",
    data() {
      return {
        //应急任务详情
        detailInfo: {},
        loading: false,
        size: 5,
        page: 1,
        total: 0,
        form: {
          name: '',
          time: []
        },
        taskList: [],
        deleting: false,
        // 任务总结弹窗
        taskSummaryVisible: false
      }
    },
    computed: {
      ...mapGetters([
        'selectEmergencyRecord',
        'emergencyRecordsList'
      ]),
      title() {
        if (this.$route.query &&  this.$route.query.name) {
          return this.$route.query.name
        }
        if (this.detailInfo && this.detailInfo.name) {
          return this.detailInfo.name
        }
        return ''
      }
    },
    watch: {},
    mounted() {
      this.getEmergencyTaskDetail()
      this.getRecordList()
    },
    methods: {
      ...mapMutations([
        'SET_SELECT_EMERGRNCY_RECORD',
        'SET_EMERGRNCY_RECORDS_LIST'
      ]),

      backToList() {
        this.$router.push('/emergency/emergency-task');
      },

      getEmergencyTaskDetail(){
        const id = this.$route.query.projectId
        getEmergencytaskDetail({
          id
        }).then(res=>{
          if (res.data.code === 200) {
            this.detailInfo = res.data.data
          }
        })
      },

      searchHandle() {
        this.getRecordList()
      },

      chooseRecord(node) {
        this.SET_SELECT_EMERGRNCY_RECORD(node)
      },

      handleSizeChange(v) {
          this.size = v
          this.page = 1
          this.getRecordList()
      },

      handleCurrentChange(v) {
          this.page = v
          this.getRecordList()
      },

      getRecordList() {
        this.loading = true
        let params = {}
        let form = JSON.parse(JSON.stringify(this.form))
        for (var key in form) {
          if (key === 'time') {
            if ( form[key] && Array.isArray(form[key]) && form[key].length === 2 ) {
              params.startTime = form[key][0]
              params.endTime = form[key][1]
            }
            continue
          }
          if (form[key]) {
            params[key] = form[key]
            continue
          }
        }
        params.current = this.page
        params.size = this.size
        params.emergencyTaskId = this.$route.query.projectId
        getEmergencyRecordPage(params).then(res=>{
          if (res.data.code === 200) {
            this.SET_SELECT_EMERGRNCY_RECORD({})
            let data = res.data.data.records
            this.total = res.data.data.total
            this.SET_EMERGRNCY_RECORDS_LIST(data)
          }
        }).finally(()=>{
          this.loading = false
        })
      },

      deleteEmergencyRecord(node) {
        if (!this.deleting) {
          this.deleting = true
          return
        }
        this.loading = true
        deleteEmergencyRecord({
          ids: node.id
        }).then(res=>{
          if (res.data.code == 200) {
            this.page = 1
            this.getRecordList()
            this.$message.success(this.$t('operationSuccessful'))
          } else {
            this.loading = false
          }
        }).catch(()=>{
          this.loading = false
        }).finally(()=>{
          this.deleting = false
        })
      },

      leaveRecord() {
        this.deleting = false
      },

      flyTo(node) {
        // todo 需要和产品确定
        this.$EventBus.$emit('flyto-ET-record', node.inspectionId)
      },

      exportRecords() {
        console.log(123456789);
      },
      
      closeTaskSummary() {
        this.taskSummaryVisible = false
      },

      openTaskSummary() {
        this.taskSummaryVisible = true
      }
    },
    beforeDestroy() {
      this.SET_SELECT_EMERGRNCY_RECORD({})
    }
  };
</script>
<style lang="scss">
.emergency-records-bar {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 346px;
  background-color: rgba(30, 34, 42, 1);
  .detail-title {
    position: absolute;
    top: -49px;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #ffffff;
    padding-top: 9px;
    padding-bottom: 11px;
    display: flex;
    align-items: center;
    .icon-back {
      margin-right: 4px;
      cursor: pointer;
    }
  }
  .emergency-records-title {
    padding: 17px 22px 12px 22px;
    font-size: 14px;
    line-height: 20px;
    font-family: Microsoft Yahei UI;
    font-weight: bold;
    color: #ffffff;
    border-bottom: 1px solid #030303;
    display: flex;
    align-items: center;
    .title {
      flex: 1;
    }
  }
  .emergency-records-search {
    padding: 12px 18px 12px 22px;
    border-bottom: 1px solid #030303;
  }
  .uav-select {
    .el-input input {
      height: 30px;
    }
  }
  .uav-date-range {
    background-color: #030303;
    border-color: #444;
    .el-input__icon{
      display: none;
    }
    .el-range-input {
      background-color: transparent;
      color: #ffffff;
      width: 45%;
    }
  }
  input::placeholder {
    color: #444;
  }
  .search-icon-container {
    line-height: 24px;
    font-size: 24px;
    cursor: pointer;
    .el-icon-search{
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .margin-separate {
    margin: 20px 0px;
  }
  .record-item-box{
    flex-grow: 1;
    overflow: auto;
    .record-item {
        cursor: pointer;
        height: 126px;
        box-sizing: border-box;
        border-bottom: 1px solid #030303;
        padding: 16px 10px;
        color: rgba(153, 153, 153, 0.79);
        .record-title {
            font-size: 14px;
            font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 18px;
            display: flex;
            justify-content: space-between;
        }
        .record-task {
            height: 15px;
            margin-top: 16px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            color: rgba(153,153,153,0.79);
            line-height: 15px;
        }
        .record-describe-mane {
            color: #FFFFFF;
            height: 15px;
            overflow: hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
            max-width: 250px;
            margin-top: 8px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            line-height: 15px;
        }
        .record-describe {
            height: 15px;
            overflow: hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
            margin-top: 8px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            line-height: 15px;
        }
        .record-time {
            height: 15px;
            margin-top: 8px;
            font-size: 12px;
            font-family: MicrosoftYaHeiUI;
            line-height: 15px;
        }
        .record-show {
            color: #3573FF;
        }
    }
  }
  .record-item-active {
      background-color: #3573FF33;
  }
  .bgc-red {
    background-color: #f00;
  }
  .clickable-txt {
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: rgb(64, 158, 255);
    cursor: pointer;
  }
}
</style>
